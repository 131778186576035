import React from 'react'
import AppRouter from 'AppRouter'
import { AppTheme } from 'styles/Theme'
import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

function App() {
  return (
    <ThemeProvider theme={AppTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AppRouter />
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
