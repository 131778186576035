import axios, { AxiosResponse } from 'axios'
import { Introspection } from 'features/landing/Landing.types'
import { MARCO_URL } from 'constants/EnvVars.constants'

export async function introspectTokenUsingGet(token: string): Promise<Introspection> {
  const result: AxiosResponse<any> = await axios.request({
    method: 'GET',
    url: `${MARCO_URL}/introspect/${token}`,
  })

  return result.data
}
