import React from 'react'
import { CircularProgress, Stack } from '@mui/material'

export default function FullPageLoader() {
  return (
    <Stack height="100vh" width="100vw" justifyContent="center" alignItems="center">
      <CircularProgress disableShrink size={96} color="primary" />
    </Stack>
  )
}
