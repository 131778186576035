import { createTheme, Theme as MaterialUITheme, ThemeOptions as MaterialUIThemeOptions } from '@mui/material/styles'
import { Theme as EmotionTheme } from '@emotion/react'

interface CustomTheme {
  custom: {
    palette: {
      primary: { main: { alpha: string } }
      states: {
        internalComments: string
      }
      tertiary: {
        main: string
        light: string
        dark: string
      }
      grey: {
        10: string
        13: string
        20: string
        33: string
        54: string
        65: string
        75: string
        89: string
        ['89a']: string
        94: string
        99: string
      }
      ['blue-231']: {
        48: string
        87: string
      }
      ['green-120']: {
        30: string
        35: string
        40: string
        50: string
      }
      ['red']: {
        36: string
      }
      ['yellow-48']: { 40: string; 50: string }
      ['red-0']: { 53: string }
    }
  }
}

declare module '@mui/material/styles' {
  // @ts-ignore
  interface Theme extends MaterialUITheme, CustomTheme {}

  interface ThemeOptions extends MaterialUIThemeOptions, CustomTheme {}
}

declare module '@emotion/react' {
  // @ts-ignore
  interface Theme extends EmotionTheme, MaterialUITheme {}
}

export const AppTheme = createTheme({
  typography: {
    fontFamily: '"Inter", sans-serif',
    fontWeightRegular: '400',
    fontWeightBold: '600',
  },
  palette: {
    primary: {
      main: 'hsl(321, 53%, 31%)',
      light: 'hsla(321, 60%, 80%, 0.3)',
      dark: 'hsl(321, 100%, 20%)',
    },
    secondary: {
      main: 'hsl(183, 58%, 44%)',
      light: 'hsla(183, 60%, 80%, 0.3)',
      dark: 'hsl(183, 100%, 30%)',
    },
    warning: {
      light: 'hsl(59, 100%, 89%)',
      main: 'hsl(59, 77%, 47%)',
      dark: 'hsl(50, 100%, 31%)',
      contrastText: '#fff',
    },
  },
  custom: {
    palette: {
      primary: { main: { alpha: 'hsla(321, 53%, 31%, 0.04)' } },
      states: {
        internalComments: 'hsl(48, 100%, 55%)',
      },
      tertiary: {
        main: 'hsl(42, 41%, 92%)',
        light: 'hsla(42, 10%, 95%, 0.8)',
        dark: 'hsl(42, 70%, 80%)',
      },
      grey: {
        10: 'hsl(0, 0%, 10%)',
        13: 'hsl(0, 0%, 13%)',
        20: 'hsl(0, 0%, 20%)',
        33: 'hsl(0, 0%, 33%)',
        54: 'hsl(0, 0%, 54%)',
        65: 'hsl(0, 0%, 65%)',
        75: 'hsl(0, 0%, 75%)',
        89: 'hsl(0, 0%, 89%)',
        ['89a']: 'hsla(0, 0%, 89%, 0.4)',
        94: 'hsl(0, 0%, 94%)',
        99: 'hsl(0, 0%, 99%)',
      },
      red: {
        36: 'hsl(0, 98%, 36%)',
      },
      ['blue-231']: {
        48: 'hsl(231, 48%, 48%)',
        87: 'hsl(231, 91%, 87%)',
      },
      ['green-120']: {
        30: 'hsl(120, 94%, 30%)',
        35: 'hsl(120, 94%, 35%)',
        40: 'hsl(120, 94%, 40%)',
        50: 'hsl(120, 94%, 50%)',
      },
      ['yellow-48']: {
        40: 'hsl(48, 100%, 40%)',
        50: 'hsl(48, 100%, 50%)',
      },
      ['red-0']: {
        53: 'hsl(0, 100%, 53%)',
      },
    },
  },
} as any)
