import React, { lazy, Suspense } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import LandingView, { landingViewLoader } from 'features/landing/LandingView'
import FullPageLoader from 'features/loader/Loader'
const Root = lazy(() => import('features/root/Root'))
const NotFound = lazy(() => import('features/not-found/NotFound'))

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: '/:token',
        element: <LandingView />,
        loader: landingViewLoader,
      },
    ],
  },
])

export default function AppRouter() {
  return (
    <Suspense fallback={<FullPageLoader />}>
      <RouterProvider router={router} />
    </Suspense>
  )
}
