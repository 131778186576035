import React, { lazy, Suspense, useCallback } from 'react'
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom'
import { introspectTokenUsingGet } from 'features/landing/TokenExchange.apis'
import { Introspection, IntrospectionTypesEnum, RequestTypeEnum } from 'features/landing/Landing.types'
import FullPageLoader from 'features/loader/Loader'

const SelfServiceView = lazy(() => import('features/self-service/SelfServiceView'))
const EManifestView = lazy(() => import('features/emanifest/EManifestView'))
const NotFound = lazy(() => import('features/not-found/NotFound'))

/**
 * This is the entry point for all our experiences. It will parse the token, then decide which experience to present. This
 * in conjunction with lazy loading will make sure we are not downloading all the experiences whenever Alice visits the app.
 */
export default function LandingView() {
  const introspection = useLoaderData() as Introspection | null

  const renderView = useCallback(() => {
    if (!introspection) {
      return <FullPageLoader key="full-page-loader" />
    }
    if (
      introspection.type === IntrospectionTypesEnum.REPLY_CONTEXT &&
      introspection.requestType === RequestTypeEnum.FORM_REQUEST
    ) {
      return <EManifestView />
    }
    if (introspection.type === IntrospectionTypesEnum.SUBSCRIPTION_MANAGEMENT) {
      return <SelfServiceView />
    }

    return <NotFound />
  }, [introspection])

  return <Suspense fallback={<FullPageLoader key="full-page-loader" />}>{renderView()}</Suspense>
}

export async function landingViewLoader({ params }: LoaderFunctionArgs) {
  if (params.token) {
    return introspectTokenUsingGet(params.token)
  }

  return null
}
