export interface HasToken {
  token: string
}

export interface HasData<TData> {
  data: TData
}

export enum IntrospectionTypesEnum {
  SUBSCRIPTION_MANAGEMENT = 'subscriptionManagement',
  REPLY_CONTEXT = 'replyContext',
}

export enum RequestTypeEnum {
  FORM_REQUEST = 'co.vendorflow.automation.FormRequest',
}

export interface Introspection {
  type: IntrospectionTypesEnum
  requestType?: RequestTypeEnum
}

export type TokenBasedExperience<TData> = HasToken & HasData<TData>
